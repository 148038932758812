<template>
    <div class="fault-wrap common-tab">
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="MyTask">待我处理<span v-if="total1 > 0">（{{ total1 }}）</span></el-radio-button>
        <el-radio-button label="MyFinishTask">我已处理</el-radio-button>
      </el-radio-group>
      <div class="bottom">
      <MTable ref="mTable" :columns="columnsAll" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="projectName" slot-scope="{ row }" :path="{name:'ProjectManagementDetail',query:{id:row.projectId}}" :text="row.projectName" />
        <NewPageOpen slot="sourceNumber" slot-scope="{ row }" :path="{name:'QualityTaskDetail',query:{id:row.sourceId}}" :text="row.sourceNumber" />
        <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.businessType - 1] }}</div>
        <div slot="status" slot-scope="{ row }">
          {{ allStatus[row.status - 1] }}
        </div>
        <div slot="action" slot-scope="{ row }" style="z-index: 100">
          <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row)">详情</el-button>
        </div>
      </MTable>
      </div>
  
    </div>
  </template>
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import api from '@/api/quality/expection-task'
  import conventionMixin from '@/mixin/conventional-page'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  
  export default {
    name: 'DefectiveProductTaskList',
    components: { FunctionHeader,NewPageOpen },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        total1: 0,
        keyword: '',
        selectKeyword: '1',
        functionHeader: {
          searchVal: ''
        },
        allStatus: ['待处理','已处理','已作废'],
        inspectionTypeNames: ['自制工序自检', '自制工序专检', '委外工序检验'],
        searchDatas: [],
        params: {
            radio: 'MyTask'
          },
          columnsAll : [
            {
            prop: 'index',
            label: '序号',
            slotName: 'index',
            hiddenSearch: true,
            sortable: false,
            width: 80
            },
            {
                prop: 'number',
                label: '单号',
                hiddenSearch: true,
                sortable: false,
                width: 160
            },
            {
                prop: 'businessType',
                label: '业务类型',
                hiddenSearch: true,
                sortable: false,
                slotName: 'inspectionType'
            },
            {
                prop: 'procedureName',
                label: '工序',
                hiddenSearch: true,
                sortable: false,
                width: 120
            },
            {
            prop: 'materialsCode',
            label: '物料编码',
            hiddenSearch: true,
            sortable: false,
            width: 180
            },
            {
            prop: 'materialsName',
            label: '物料名称',
            hiddenSearch: true,
            sortable: false,
            width: 180
            },
            {
            prop: 'materialsSpecifications',
            label: '物料规格',
            hiddenSearch: true,
            sortable: false
            },
            {
            prop: 'defectQuantity',
            label: '不良品数量',
            hiddenSearch: true,
            sortable: false,
            width: 100
            },
            {
            prop: 'taskName',
            label: '当前节点',
            hiddenSearch: true,
            sortable: false,
            width: 120
            },
            {
            prop: 'endTime',
            label: '处理时间',
            hiddenSearch: true,
            sortable: false,
            width: 180
            },
            {
            prop: 'creator',
            label: '创建人',
            hiddenSearch: true,
            sortable: false
            },
            {
            prop: 'createTime',
            label: '创建时间',
            hiddenSearch: true,
            sortable: false,
            width: 180
            },
            {
            prop: 'projectName',
            label: '项目',
            hiddenSearch: true,
            slotName:'projectName',
            sortable: false
            },
            {
            prop: 'sourceNumber',
            label: '源单号',
            hiddenSearch: true,
            sortable: false,
            slotName:'sourceNumber',
            width: 180
            },
            {
            slotName: 'action',
            label: '操作',
            fixed: 'right',
            sortable: false,
            hiddenSearch: true,
            width: 120
            }
        ],
        isPageLoaded:false
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192
      }
    },
    watch: {
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
      radioChange() {
        this.$refs.mTable.setTableData(true)
      },
      viewDetail(item) {
        item.editFieldMap ? '' : item.editFieldMap = {}
        const a = item.editFieldMap
        const canEditColumns = []
        const canEditColumns_mastHaveValue = []
        for (let key in a) {
          if (a.hasOwnProperty(key)) {
            canEditColumns.push(key)
            if (a[key] == true) {
              canEditColumns_mastHaveValue.push(key)
            }
          }
        }
        item.buttonNameList ? '' : item.buttonNameList = []
        const obj = {
          'editFieldList': canEditColumns,
          'mastHaveValue': canEditColumns_mastHaveValue,
          'buttonNameList': item.buttonNameList,
          'id': item.businessObject.id,
          'taskId': item.taskId,
          "showFlag":this.params.radio === 'MyTask' ? 0 : 1
        }
        this.$router.push({ name: 'DefectiveProductTaskDetail', query: { id:item.businessObject.id,needInfo: encodeURIComponent(JSON.stringify(obj)) }})
      },
      // 列表
      async getTableData(condition, callback) {
        const { page, order } = condition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          limit: page.pageSize,
          processDefinitionKey: 'defectProduct'
        })
        if (res) {
            res.records.forEach(item => {
                item.number = item.businessObject.number
                item.businessType = item.businessObject.businessType
                item.status = item.businessObject.status
                item.materialsName = item.businessObject.materialsName
                item.materialsSpecifications = item.businessObject.materialsSpecifications
                item.defectQuantity = item.businessObject.defectQuantity
                item.creator = item.businessObject.creator
                item.createTime = item.businessObject.createTime
                item.sourceNumber = item.businessObject.sourceNumber
                item.projectName = item.businessObject.projectName
                item.sourceId = item.businessObject.sourceId
                item.projectId = item.businessObject.projectId
                item.procedureName = item.businessObject.procedureName
                item.materialsCode = item.businessObject.materialsCode
        })
        if(this.params.radio === 'MyTask'){
            this.total1 = res.total
        }
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      }
    }
  }
  </script>
    <style scoped lang="scss">
     .fault-wrap{
        padding: 10px;
        .item-wrapper {
          > div {
            display: inline-flex;
            padding: 10px 5px 0 5px;
            align-items: center;
            font-size: 14px;
  
            .label {
              min-width: 115px;
            }
  
          }
        }
        .bottom {
          padding-top: 10px;
        }
        .color-btns{
          color:#c4c4c4;
        }
     }
    </style>  