var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { "margin-bottom": "10px" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "process" } }, [
            _vm._v("过程检")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForms", {
                ref: "searchForms",
                attrs: {
                  "show-procedure": _vm.params.radio === "process",
                  "form-option": _vm.formOptions
                },
                on: { resetData: _vm.resetData, searchData: _vm.searchTable }
              })
            ],
            1
          ),
          _c("el-divider", { staticClass: "e-divider" }),
          _c(
            "FunctionHeader",
            {
              staticStyle: { padding: "10px 0 10px 0" },
              attrs: {
                "search-title": "输入任务编号搜索",
                tags: _vm.functionHeader.tags
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectIds.length,
                      expression: "selectIds.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("Print")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handlePreview(_vm.selectIds, 1)
                            }
                          }
                        },
                        [_vm._v("批量打印")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + " 项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns:
                _vm.params.radio === "process" ? _vm.columnsAll : _vm.incomming,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            on: { "selection-change": _vm.selectionChanges },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "number",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "qualityInspectionOrderDetail",
                                query: { id: row.id, isView: "view" }
                              },
                              text: row.number
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "inspectionType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.inspectionTypeNames[row.type]))
                  ])
                }
              },
              {
                key: "passRate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        row.okCount > 0
                          ? ((row.okCount / row.batchCount) * 100).toFixed(2)
                          : 0
                      ) + "%"
                    )
                  ])
                }
              },
              {
                key: "passAndUnPass",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.okCount) + "/" + _vm._s(row.ngCount))
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background:
                          _vm.qualityTaskStatus[row.status].background,
                        color: _vm.qualityTaskStatus[row.status].color
                      }
                    }),
                    _c(
                      "div",
                      {
                        style: {
                          color: _vm.qualityTaskStatus[row.status].color
                        }
                      },
                      [_vm._v(_vm._s(_vm.qualityTaskStatus[row.status].name))]
                    )
                  ])
                }
              },
              {
                key: "allCount",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(row.okCount) + "/" + _vm._s(row.ngCount))
                    ])
                  ])
                }
              },
              {
                key: "result",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        style: {
                          color:
                            row.result == 0
                              ? "#E02020"
                              : row.result === 1
                              ? "#00AB29"
                              : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.result === 1
                                ? "合格"
                                : row.result === 0
                                ? "不合格"
                                : "-"
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                }
              },
              {
                key: "reports",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.views(row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticStyle: { "z-index": "100" } },
                    [
                      _vm.permission("Detail")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row.id)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        : _vm._e(),
                      _vm.permission("Del")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.del(row.id)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm.permission("Print")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handlePreview(row, 0)
                                }
                              }
                            },
                            [_vm._v("打印")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            title: "",
            visible: _vm.dialog,
            "show-close": false,
            direction: "rtl",
            "custom-class": "demo-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "body-wrap",
              style: { height: _vm.dialogHeight + "px" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [_c("i", { staticClass: "el-icon-printer icons" })]
              ),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("打印")
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "打印模板",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择打印模板"
                              },
                              on: { change: _vm.changeTemplate },
                              model: {
                                value: _vm.form.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type"
                              }
                            },
                            _vm._l(_vm.templateList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.templateName,
                                  value: item.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("i", {
                    staticClass: "el-icon-setting",
                    staticStyle: {
                      "margin-bottom": "12px",
                      color: "#607FFF",
                      "font-size": "22px",
                      "margin-left": "10px",
                      cursor: "pointer"
                    },
                    on: { click: _vm.setTemplate }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c("el-button", { on: { click: _vm.cancelForm } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.dowmLoadTemplate()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.loading ? "下载中 ..." : "下 载"))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.printLoading },
                  on: {
                    click: function($event) {
                      return _vm.printTemplate()
                    }
                  }
                },
                [_vm._v("打 印")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }