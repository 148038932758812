import formList from '@/views/qualityManagement/quality-project/form-list'
import { getFunctionList } from '@/views/qualityManagement/quality-project/columns'
import projectGroupApi from '@/api/quality/inspection-project'
import projectItemApi from '@/api/quality/inspection-items'
import { getToken } from '@/utils/auth'

export default {
  data() {
    return {
      addItemVisible: false,
      formData: {},
      formList,
      currentIndex: null,
      currentColumnIndex: null,
      groupVisible: false,
      itemfileList: [],
      currentType: 'add',
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload2,
        onSuccess: this.fileUploadChange2,
        onError: this.fileUploadChange2,
        onRemove: this.removeFile
      },
      addGroupType: 'front',
      qualifiedValue:[{name:'合格'},{name:'不合格'}],
    }
  },
  beforeMount() {
    getFunctionList({
      paramsTypeChange: this.paramsTypeChange,
      possibleValueChange: this.possibleValueChange
    })
  },
  mounted(){
    this.getUnitLists()
  },
  methods: {
    async getUnitLists(){
      const res = await projectItemApi.getUnitList()
      if(res){
        this.formList[7].children[0].options = res
      }
    },
    addInspectionItems(index) {
      this.currentColumnIndex = null
      this.itemfileList = []
      this.currentIndex = index
      this.formData = {}
      this.currentType = 'add'
      this.formList[4].children[0].options = []
      this.paramsTypeChange(1)
      this.addItemVisible = true
    },
    modifyInspectionItem(columnIndex, cardIndex) {
      this.itemfileList = []
      this.currentType = 'modify'
      this.currentIndex = cardIndex
      this.currentColumnIndex = columnIndex
      this.formData = this._.cloneDeep(this.projectList[this.currentIndex].inspectionItemList[0].data[columnIndex - 1])
      if (this.formData.attachments) {
        this.itemfileList = JSON.parse(this.formData.attachments)
      }
      if ([0, 1, 4].includes(this.formData.parameterType)) {
        this.formData.possibleValue = []
      }
      this.paramsTypeChange(this.formData.parameterType)
      this.addItemVisible = true
    },
    paramsTypeChange(val) {
      // 检验条目类型 0:数值 1:文本 2:单选 3:多选 4:附件
      this.formData.parameterType = val
      let possibleValue = this.formData.possibleValue ? this.formData.possibleValue.map(item => ({ id: item, name: item })) : []
      if (this.$refs.formBuild) {
        possibleValue = this.$refs.formBuild.form.possibleValue ? this.$refs.formBuild.form.possibleValue.map(item => ({ id: item, name: item })) : []
        this.$refs.formBuild.formRefs().clearValidate()
        this.$refs.formBuild.setForm([0, 1, 4].includes(val) ? {
          defaultValue: null,
          possibleValue: []
        } : {
          defaultValue: val === 2 ? null : []
        })
      }
        // 选择数值的时候添加单位
        if (val === 0) {
        this.formList[4].hidden = true
        this.formList[7].hidden = false
        this.formList.splice(5, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: val === 0 ? 'el-input-number' : 'el-input',
          props: val === 0 ? { controlsPosition: 'right' } : {},
          children: []
        })
      }
      if (val === 1) {
        this.formList[4].hidden = true
        this.formList[7].hidden = true
        this.formList.splice(5, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: val === 0 ? 'el-input-number' : 'el-input',
          props: val === 0 ? { controlsPosition: 'right' } : {},
          children: []
        })
      }
      if (val === 2 || val === 3) {
        this.formList[4].hidden = false
        this.formList[7].hidden = true
        this.formList.splice(5, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: 'el-select',
          props: { multiple: val === 3 },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: possibleValue
            }
          ]
        })
      }else if(val === 4){
        this.formList[4].hidden = true
        this.formList[7].hidden = true
        this.formList.splice(5, 1, {
        label: '默认值',
        key: 'defaultValue',
        tagName: 'el-select',
        props: { multiple: val === 3 },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'name'
            },
            options: this.qualifiedValue
          }
        ]
      })
    }
    },
    possibleValueChange(val) {
      this.formList[5].children[0].options = val.map(item => ({ name: item, id: item }))
    },
    beforeUpload2(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange2(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.itemfileList.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    submitFormItem(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const raw = form
          const data = {
            inspectionEntryName: raw.inspectionEntryName,
            inspectionProjectName: this.projectList[this.currentIndex].inspectionProjectName,
            parameterType: raw.parameterType,
            defaultValue: raw.defaultValue,
            possibleValue: [2, 3].includes(raw.parameterType) ? raw.possibleValue || [] : '',
            inspectionMethod: raw.inspectionMethod,
            inspectionStandard: raw.inspectionStandard,
            attachments: JSON.stringify(this.itemfileList || []),
            isEnabled: raw.isEnabled,
            unitId:raw.unitId,
            remark: raw.remark
          }
          if (this.projectList[this.currentIndex].columns.length) {
            this.addInspectionItem(data, form, callback)
          } else {
            this.transferItemToTable([data], this.currentIndex)
            this.addItemVisible = false
            callback(true)
          }
        } else {
          callback()
        }
      })
    },
    addInspectionItem(data, form, callback) {
      const columns = {
        headerSlotName: 'headerSlot',
        hiddenSearch: true,
        label: form.inspectionEntryName,
        minWidth: 310,
        prop: form.inspectionEntryName,
        slotName: 'customRow',
        sortable: false
      }
      if (this.currentColumnIndex === null) {
        this.projectList[this.currentIndex].columns.push(columns)
        this.projectList[this.currentIndex].inspectionItemList.forEach(item => {
          item.data.push(data)
        })
      } else {
        this.projectList[this.currentIndex].columns.splice(this.currentColumnIndex, 1, columns)
        this.projectList[this.currentIndex].inspectionItemList.forEach(item => {
          item.data.splice(this.currentColumnIndex - 1, 1, data)
        })
      }
      this.addItemVisible = false
      callback(true)
    },
    saveAs(index) {
      this.addGroupType = 'end'
      this.formData = this._.cloneDeep(this.projectList[index])
      this.groupVisible = true
    },
    addProjectGroupFront(index) {
      this.addGroupType = 'front'
      this.currentIndex = index
      this.formData = {}
      this.groupVisible = true
    },
    async submitGroupForm(callback) {
      if (!this.formData.inspectionProjectName) {
        callback()
        return this.$message.info('请输入检验组名称')
      }
      if (this.addGroupType === 'end') {
        await this.checkGroupName(callback)
      } else {
        const nameIsRepeat = this.formOptions.inspectionProjectList.find(item => item.name === this.formData.inspectionProjectName)
        callback()
        if (nameIsRepeat) return this.$message.info('检验组名称重复')
        this.formOptions.inspectionProjectList.unshift({
          name: this.formData.inspectionProjectName
        })
        this.inspectionProjectChange(this.formData.inspectionProjectName, this.currentIndex)
        this.groupVisible = false
      }
    },
    async checkGroupName(callback) {
      const check = await projectGroupApi.checkSameProjectName({ inspectionProjectName: this.formData.inspectionProjectName })
      if (!check) {
        await this.addGroupAndBindItems(callback)
      } else {
        this.$confirm('检验组已存在，您确定要覆盖吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          await this.addGroupAndBindItems(callback)
        })
      }
    },
    async addGroupAndBindItems(callback) {
      const res = await projectGroupApi.addProjectGroupAndBindItems({
        name: this.formData.inspectionProjectName,
        inspectionEntryList: this.formData.inspectionItemList[0].data.map(raw => ({
          inspectionEntryName: raw.inspectionEntryName,
          inspectionProjectName: this.formData.inspectionProjectName,
          parameterType: raw.parameterType,
          defaultValue: Array.isArray(raw.defaultValue) ? raw.defaultValue.length ? JSON.stringify(raw.defaultValue) : '' : raw.defaultValue,
          possibleValue: [2, 3].includes(raw.parameterType) ? JSON.stringify(raw.possibleValue || []) : '',
          inspectionMethod: raw.inspectionMethod,
          inspectionStandard: raw.inspectionStandard,
          attachments: Array.isArray(raw.attachments) ? JSON.stringify(raw.attachments) : raw.attachments,
          isEnabled: raw.isEnabled,
          remark: raw.remark
        }))
      })
      if (res) {
        this.groupVisible = false
        this.getInspectionItemList()
        this.$message.success('保存成功')
      }
      callback()
    }
  }
}
