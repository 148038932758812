var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "MyTask" } }, [
            _vm._v("待我处理"),
            _vm.total1 > 0
              ? _c("span", [_vm._v("（" + _vm._s(_vm.total1) + "）")])
              : _vm._e()
          ]),
          _c("el-radio-button", { attrs: { label: "MyFinishTask" } }, [
            _vm._v("我已处理")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columnsAll,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "projectName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "ProjectManagementDetail",
                        query: { id: row.projectId }
                      },
                      text: row.projectName
                    }
                  })
                }
              },
              {
                key: "sourceNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "QualityTaskDetail",
                        query: { id: row.sourceId }
                      },
                      text: row.sourceNumber
                    }
                  })
                }
              },
              {
                key: "inspectionType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(_vm.inspectionTypeNames[row.businessType - 1])
                    )
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.allStatus[row.status - 1]) + " ")
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticStyle: { "z-index": "100" } },
                    [
                      _vm.permission("Detail")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewDetail(row)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }