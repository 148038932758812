import { businessType } from '@/config/options.config'
export const searchFormList = [
    {
      colSpan: 4,
      key: 'businessType',
      tagName: 'el-select',
      props: {
        placeholder: '请选择业务类型'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: businessType
        }
      ]
    },
    {
        slotName: 'finishedMaterial',
        colSpan: 4,
        key: 'materialsId',
        tagName: 'el-select',
        props: {
          placeholder: '请选择成品物料',
          configFilter: true,
          remote: true,
          remoteMethod: (val) => {
            console.log('远程搜', val)
          }
        },
        directives: [
          { name: 'selectScrollEnd',
            value: () => {
              console.log('滚动触底了，可以请求下一页了')
            }
          }
        ],
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'fullLabel', // 自定义label需要字段值与configLabel字段值一致
              value: 'id'
            },
            options: []
          }
        ]
      },
    {
        colSpan: 4,
        key: 'procedureId',
        tagName: 'el-select',
        props: {
          placeholder: '请选择工序'
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ]
      },
    {
      colSpan: 4,
      key: 'projectId',
      tagName: 'el-select',
      props: {
        placeholder: '请选择项目',
        configFilter: true,
        remote: true,
        remoteMethod: (val) => {
          console.log('远程搜', val)
        }
      },
      directives: [
        { name: 'selectScrollEnd',
          value: () => {
            console.log('滚动触底了，可以请求下一页了')
          }
        }
      ],
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'projectName',
            value: 'projectId'
          },
          options: []
        }
      ]
    },
    {
        colSpan: 4,
        key: 'sourceNumber',
        tagName: 'el-input',
        props: {
          placeholder: '请输入源单号'
        }
      }
  ]